import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddClient from '../Invoice/AddClient';
import AddProduct from '../A/AddProduct'
import { AddBox } from '@material-ui/icons';
import { useTranslation } from "react-i18next";



const FabButton = () => {
  
  const { t } = useTranslation();
  const location = useLocation()
  const mainButtonStyles = {backgroundColor: '#1976D2'}
  const [open, setOpen] = useState(false)
  const [popen, setPopen] = useState(false)

  // if(location.pathname === '/invoice') return null

    return (
        <div>
           <AddClient setOpen={setOpen} open={open} />
           <AddProduct setPopen={setPopen} popen={popen} />
          <Fab
            mainButtonStyles={mainButtonStyles}
            icon={ <AddIcon />}
            alwaysShowTitle={true}
          >

            {location.pathname !== '/invoice' && (
              <Action
                  text={t("New Invoice")}
                  // onClick={() =>  history.push(`/invoice`)}
                  onClick={() => window.location.href='/invoice'}
                >
                  <CreateIcon />
              </Action>
            )}
             
            <Action
                text={t("New Customer")}
                onClick={() => { 
                  setOpen((prev) => !prev);
                }}
              >
                <PersonAddIcon />
            </Action>
            
            <Action
                text={t("New Product")}
                onClick={() => {
                  setPopen(!popen);
                }}
              >
                <AddBox />
            </Action>
             

          </Fab>
        </div>
    )
}

export default FabButton
