import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const Ordersuccess = () => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const history = useHistory()

    const logout = setTimeout(() => {
        dispatch({ type: 'LOGOUT' })
        history.push('/login')
    }, 2000)


    if(id === 'true') {

        return(
            <div onLoad={()=> logout()}>
            <h1  style={{ textAlign:'center', color:'blue' }}>Thank you for your order</h1>
            <h2  style={{ textAlign:'center', color:'blue' }}>Please wait while we redirect....</h2>
            </div>
            
        )
        
    }
        return(
            <div onLoad={()=> logout()}>
            <h1  style={{ textAlign:'center', color:'blue' }}>Your order has been cancelled</h1>
            <h2  style={{ textAlign:'center', color:'blue' }}>Please wait while we redirect.......</h2>
            </div>
        )
        
}

export default Ordersuccess