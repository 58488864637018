import React from 'react'
import styles from './Home.module.css'
import { useHistory } from "react-router-dom";
import img from "../images/homelogo.png"
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from "react-i18next";


const Home = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem("profile"));
    if (user) {
        history.push("/dashboard");
      }
   
    return (
        <div className={styles.pageContainer}>
            
            <section className={styles.hero}>
                <h1>{t("Accounting has never been easier!!!")}</h1>
                
                <div className={styles.paragraph}>
                <p>{t("Make an accessible and adequate accounting and save time by digitalizing your sales processes into accounting entries.")}</p>
                </div>
                <br></br>
                <div className={styles.imgContainer}>
                    <img src={img} alt="invoicing-app"/>
                </div>
                <h3 style={{ color: 'DodgerBlue', textAlign:'center'}}>{("Account Receivables made simple.")}</h3>
            </section>

            <section className={styles.hero2}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <CardContent>
                            <Typography variant="h6" sx={{ fontSize: 14, padding: 2 }} color="text.secondary" gutterBottom>
                                    {t("Create Quotes, Invoices and Rent receipt Easily prepare your quotes and your invoices in less than 5 minutes.")}
                            </Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={12} md={6}>
                <CardContent>
                        <Typography variant="h6" sx={{ fontSize: 14, padding: 2 }} color="text.secondary" gutterBottom>
                         {t("DISCOVER OUR OFFER 1 month without commitment 6€ tax incl.")}
                                 <br></br>
                        </Typography>
                </CardContent>
                </Grid>
                <Grid item xs={12} md={12}>
                <CardContent>
                            <Typography variant="h6" sx={{ fontSize: 14, padding: 2 }} color="text.secondary" gutterBottom>
                            {t("Contact us at (07 69 84 60 62) from 8 a.m. to 10 p.m. or email us at support@mafacturedevis.com. Don't wait, register now! 😊")}
                            </Typography>
                    </CardContent>
                </Grid>
            </Grid>
            </section>
        </div>
    )
}

export default Home
