import React from "react";
import styles from "./billing.module.css";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

const Billing = () => {

  const {t} = useTranslation();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("profile"));
  console.log(user);

  if (!user) {
    history.push("/login");
  }

  return (
    <div className={styles.pageContainer}>
        <section className={styles.hero}  >
          <h1>{t("Billing Information")}</h1>
          <div className={styles.paragraph} style={{ color: "black" }}>
            {user?.result.subscriptionStatus === 1 ? 
              <p>{t("You are subscribed")}</p>
             : 
              <p>{t("You are not subscribed")}</p>
            }
          </div>
          <div style={{ display: "flex", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
               maxWidth: "400px", margin: 'auto'}}>
             <section
                className={styles.hero}
                style={{ alignItems: "center", margin: 'auto' }}
                >
                    <div className="product" style={{ margin: 'auto' }}>
                    <h3>{t("Subscription")}</h3>
                    <h5 style={{ margin:'auto' }} className={styles.paragraph}>€6.00 / month</h5>
                    <form
                    style={{ alignItems: "center", margin: 'auto' }}
                    action={process.env.REACT_APP_API + "/create-checkout-session"}
                    method="POST"
                    >
                    {/* Add a hidden field with the lookup_key of your Price */}
                    <input
                        type="hidden"
                        name="lookup_key"
                        value='price_1L5toYHZwXp0Vu8MvgCXdoFs'
                    />
                    <input
                        type="hidden"
                        name="useremail"
                        value={user?.result.email}
                    />
                    <Button
                        disabled={user?.result.subscriptionStatus === 1}
                        variant="contained"
                        style={{ justifyContentContent: "center", marginLeft: "-50px" }}
                        type="submit"
                        color="primary"
                        size="large"
                    >
                        {t("Subscribe")}
                    </Button>
                    </form>
                    </div>
                    <form
                    action={process.env.REACT_APP_API + "/create-portal-session"}
                    method="POST"
                    >
                    <input
                        type="hidden"
                        name="useremail"
                        value={user?.result.email}
                    />
                    <input
                        type="hidden"
                        name="clientid"
                        value={user?.result.clientId}
                    />
                    <Button
                        variant="contained"
                        style={{ justifyContentContent: "center", marginLeft: "-50px", width: "120px"}}
                        type="submit"
                        color="primary"
                        size="large"
                    >
                        {t("Manage Billing")}
                    </Button>
                    </form>
                </section>
                </div>
          </section>
    </div>
);
};

export default Billing;
