 /* eslint-disable */
 import React, { useState, useEffect} from 'react'
 import Products from './Products'
 import AddProduct from './AddProduct'
 import { getProductsByUser } from '../../actions/productActions'
 import { useDispatch, useSelector } from 'react-redux'
 import { useLocation, useHistory } from 'react-router-dom'
 import NoData from '../svgIcons/NoData'
 import Spinner from '../Spinner/Spinner'



 const ProductList = ({pcount}) => {

const history = useHistory()
const location = useLocation()
const [open, setOpen] = useState(false)
const [popen, setPopen] = useState(false)
const [currentId, setCurrentId] = useState(null)
const dispatch = useDispatch()
const user = JSON.parse(localStorage.getItem('profile'))
const {products} = useSelector((state) => state.products)
const isLoading = useSelector(state => state.products.isLoading)
const plength = products.length

    useEffect(() => {
        dispatch(getProductsByUser({ search: user?.result?._id || user.result.googleId }));
      },[location, popen])
    
      if(!user) {
        history.push('/login')
      }
 console.log(pcount);
        
  if(isLoading) {
    return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
        <Spinner />
    </div>
  }

  if(products.length === 0) {
    return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px', margin: '80px'}}>
      <NoData />
    <p style={{padding: '40px', color: 'gray', textAlign: 'center'}}>No products yet. Click the plus icon to add products</p>
  
    </div>
  }

  return (
    <div>
        <AddProduct
            popen={popen} 
            setPopen={setPopen}
            currentId={currentId}
            setCurrentId={setCurrentId}
        />
        <Products 
            popen={popen} 
            setPopen={setPopen}
            currentId={currentId}
            setCurrentId={setCurrentId}
            products={products}
        />
    </div>
)
}

export default ProductList
