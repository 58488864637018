import React, { useState, useEffect } from "react";
import styles from "./Invoice.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toCommas } from "../../utils/utils";
import { useSnackbar } from 'react-simple-snackbar'
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { Container, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { initialState } from "../../initialState";
import currencies from "../../currencies.json";
import {
  createInvoice,
  getInvoice,
  updateInvoice,
} from "../../actions/invoiceActions";
import { getClientsByUser } from "../../actions/clientActions";
import { getProductsByUser } from "../../actions/productActions";
import AddClient from "./AddClient";
import InvoiceType from "./InvoiceType";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));

const Invoice = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [rates, setRates] = useState(0);
  const [vat, setVat] = useState(0);
  const [currency, setCurrency] = useState(currencies[75].value);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(
    today.getTime() + 7 * 24 * 60 * 60 * 1000
  );
  const [usrprfl, setUsrprfl] = useState();
  const [client, setClient] = useState(null);
  const [type, setType] = useState("Invoice");
  const [status, setStatus] = useState("");
  const [prodname, setProdname] = useState("");
  const [prdrate, setPrdRate] = useState(0);
  const { id } = useParams();
  const clients = useSelector((state) => state.clients.clients);
  const { invoice } = useSelector((state) => state.invoices);
  const { products } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("profile"));
     // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar()

  useEffect(() => {
    getTotalCount();

    // eslint-disable-next-line
  }, [location]);

  
  useEffect(()=>{axios.post(`${process.env.REACT_APP_API}/users/getuser`, {
    email: user?.result?.email,
  })
  .then(function (response) {
    setUsrprfl(response)
  })
  .catch(function (error) {
    console.log(error);
  });
  //eslint-disable-next-line
},[location])
 


  const getTotalCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/invoices/count?searchQuery=${user?.result?._id}`
      );
      //   console.log(response.data);
      //Get total count of invoice from the server and increment by one to serialized numbering of invoice
      setInvoiceData({
        ...invoiceData,
        invoiceNumber: (Number(response.data) + 1).toString().padStart(3, "0"),
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(getInvoice(id));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    dispatch(
      getClientsByUser({ search: user?.result._id || user?.result?.googleId })
    );
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getProductsByUser({ search: user?.result._id || user?.result?.googleId })
    );
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (invoice) {
      //Automatically set the default invoice values as the ones in the invoice to be updated
      setInvoiceData(invoice);
      setRates(invoice.rates);
      setClient(invoice.client);
      setType(invoice.type);
      setStatus(invoice.status);
      setSelectedDate(invoice.dueDate);
      setCurrency(invoice.currency);
    }
  }, [invoice]);

  useEffect(() => {
    if (type === "Receipt") {
      setStatus("Paid");
    } else if(type === "Reçu"){
      setStatus("Payé")
    }else if(type === "Quotation"){
      setStatus("Unpaid");
    }else if(type === "Bill"){
      setStatus("Unpaid");
    }else if(type === "Estimate"){
      setStatus("Unpaid");
    }else if(type === "Invoice"){
      setStatus("Unpaid");
    }else if(type === "Rent Receipt"){
      setStatus("Unpaid");
    }
    else {
      setStatus("Non payé");
    }
  }, [type]);

  const defaultProps = {
    options: currencies,
    getOptionLabel: (option) => option.label,
  };

  const clientsProps = {
    options: clients,
    getOptionLabel: (option) => option.name,
  };

  const productProps = {
    options: products,
    getOptionLabel: (option) => option.name,
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleRates = (e) => {
    setRates(e.target.value);
    setInvoiceData((prevState) => ({ ...prevState, tax: e.target.value }));
  };

  const handleRate = (prodrate) => {
    if (prodrate.name === prodname) {
      setPrdRate(prodrate.unitprice);
    }
  };

  // Change handler for dynamically added input field
  const handleChange = (index, e) => {
    const values = [...invoiceData.items];
    const itmname = [...invoiceData.items];
    const prrate = [...invoiceData.items];
    values[index][e.target.name] = e.target.value;
    itmname[index].itemName = prodname;
    prrate[index].unitPrice = prdrate;
    setInvoiceData({ ...invoiceData, items: values });
    setInvoiceData({ ...invoiceData, items: itmname });
    setInvoiceData({ ...invoiceData, items: prrate });
  };

  useEffect(() => {
    //Get the subtotal
    const subTotal = () => {
      var arr = document.getElementsByName("amount");
      var subtotal = 0;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].value) {
          subtotal += +arr[i].value;
        }
        // document.getElementById("subtotal").value = subtotal;
        setSubTotal(subtotal);
      }
    };

    subTotal();
  }, [invoiceData, prodname, prdrate]);

  useEffect(() => {
    const total = () => {
      //Tax rate is calculated as (input / 100 ) * subtotal + subtotal
      const overallSum = (rates / 100) * subTotal + subTotal;
      //VAT is calculated as tax rates /100 * subtotal
      setVat((rates / 100) * subTotal);
      setTotal(overallSum);
    };
    total();
  }, [invoiceData, rates, subTotal, prdrate]);

  const handleAddField = (e) => {
    e.preventDefault();
    setPrdRate(0);
    setInvoiceData((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        { itemName: "", unitPrice: "", quantity: 0, discount: "", amount: "" },
      ],
    }));
  };

  const handleRemoveField = (index) => {
    const values = invoiceData.items;
    if (index !== 0) {
      values.splice(index, 1);
      setInvoiceData((prevState) => ({ ...prevState, values }));
      setPrdRate(0);
    }
    return;
    // console.log(values)
  };

  // console.log(invoiceData)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (invoice) {
      dispatch(
        updateInvoice(invoice._id, {
          ...invoiceData,
          subTotal: subTotal,
          total: total,
          vat: vat,
          rates: rates,
          currency: currency,
          dueDate: selectedDate,
          client,
          type: type,
          status: status,
        })
      );
      history.push(`/invoice/${invoice._id}`);
    } else {
      dispatch(
        createInvoice(
          {
            ...invoiceData,
            subTotal: subTotal,
            total: total,
            vat: vat,
            rates: rates,
            currency: currency,
            dueDate: selectedDate,
            invoiceNumber: `${
              invoiceData.invoiceNumber < 100
                ? Number(invoiceData.invoiceNumber).toString().padStart(3, "0")
                : Number(invoiceData.invoiceNumber)
            }`,
            client,
            type: type,
            status: status,
            paymentRecords: [],
            author: user?.result._id,
            creator: [user?.result?._id || user?.result?.googleId],
          },
          history,
          openSnackbar
        )
      );
    }

    // setInvoiceData(initialState)
  };

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const CustomPaper = (props) => {
    return <Paper elevation={3} {...props} />;
  };

  if (!user) {
    history.push("/login");
  }

  return (
    <div className={styles.invoiceLayout}>
      <form onSubmit={handleSubmit} className="mu-form">
        <AddClient setOpen={setOpen} open={open} />
        <Container className={classes.headerContainer}>
          <Grid container justifyContent="space-between">
            <Grid item>
              {/* <Avatar alt="Logo" variant='square' src="" className={classes.large} /> */}
            </Grid>
            <Grid item>
              <InvoiceType type={t(type)} setType={setType} />
              {t("Invoice")} #:
              <div
                style={{
                  marginTop: "15px",
                  width: "100px",
                  padding: "8px",
                  display: "inline-block",
                  backgroundColor: "#f4f4f4",
                  outline: "0px solid transparent",
                }}
                contentEditable="true"
                onInput={(e) =>
                  setInvoiceData({
                    ...invoiceData,
                    invoiceNumber: e.currentTarget.textContent,
                  })
                }
              >
                <span
                  style={{ width: "40px", color: "black", padding: "15px" }}
                >
                  {" "}
                  {invoiceData.invoiceNumber}
                </span>
                <br />
              </div>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Container>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "40px" }}
          >
            <Grid item style={{ width: "50%" }}>
              <Container>
                <Typography
                  variant="overline"
                  style={{ color: "gray", paddingRight: "3px" }}
                  gutterBottom
                >
                  {t("Bill to")}
                </Typography>

                {client && (
                  <>
                    <Typography variant="subtitle2" gutterBottom>
                      Name :{client.name}
                    </Typography>
                    <Typography variant="body2">Email : {client.email}</Typography>
                    <Typography variant="body2">PH : {client.phone}</Typography>
                    <Typography variant="body2">Adr. : {client.address}</Typography>
                    <Typography variant="body2">SIREN : 
                      {client.businessnumber1}
                    </Typography>
                    <Typography variant="body2">SIRET : 
                      {client.businessnumber2}
                    </Typography>
                    <Button
                      color="primary"
                      size="small"
                      style={{ textTransform: "none" }}
                      onClick={() => setClient(null)}
                    >
                      {t("Change")}
                    </Button>
                  </>
                )}
                <div
                  style={client ? { display: "none" } : { display: "block" }}
                >
                  <Autocomplete
                    {...clientsProps}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={!invoice && true}
                        label={t("Select Customer")}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                    value={clients?.name}
                    onChange={(event, value) => setClient(value)}
                  />
                </div>
                {!client && (
                  <>
                    <Grid item style={{ paddingBottom: "10px" }}>
                      <Chip
                        avatar={<Avatar>+</Avatar>}
                        label={t("New Customer")}
                        onClick={() => setOpen(true)}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}
              </Container>
            </Grid>

            <Grid item style={{ marginRight: 20, textAlign: "right" }}>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                {t("Status")}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: type === "Receipt" ? "green" : type === "Reçu" ? "green" : "red" }}
              >
                {type === "Receipt" ? "Paid" : type === "Reçu" ? "Payé" :  
                 type === "Quotation" ? "Unpaid" : type === "Bill" ? "Unpaid" :
                 type === "Estimate" ? "Unpaid" : type === "Invoice" ? "Unpaid" :
                 type === "Rent Receipt" ? "Unpaid" : "Non payé"}
              </Typography>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                {t("Date")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {moment().format("MMM Do YYYY")}
              </Typography>
              <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                {t("Due Date")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {selectedDate
                  ? moment(selectedDate).format("MMM Do YYYY")
                  : "27th Sep 2021"}
              </Typography>
              <Typography variant="overline" gutterBottom>
                {t("Amount")}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {currency} {toCommas(total)}
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <div>
          <TableContainer component={Paper} className="tb-container">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Item")}</TableCell>
                  <TableCell>{t("Qty")}</TableCell>
                  <TableCell>{t("Price")}</TableCell>
                  <TableCell>Disc(%)</TableCell>
                  <TableCell>{t("Amount")}</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice
                  ? invoiceData.items.map((itemField, index) => (
                      <TableRow key={index}>
                        {itemField.itemName ? (
                          <TableCell scope="row" style={{ width: "40%" }}>
                            <InputBase
                              style={{ width: "100%" }}
                              outline="none"
                              sx={{ ml: 1, flex: 1 }}
                              type="text"
                              name="itemName"
                              onChange={(e) => handleChange(index, e)}
                              value={itemField.itemName}
                              placeholder="Item name or description"
                            />{" "}
                          </TableCell>
                        ) : (
                          <TableCell
                            onInput={handleChange}
                            scope="row"
                            style={{ width: "40%" }}
                          >
                            <Autocomplete
                              {...productProps}
                              PaperComponent={CustomPaper}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Product"
                                  margin="normal"
                                  name="itemName"
                                  value={itemField.itemName}
                                />
                              )}
                              value={products.name}
                              onChange={(e, value) => {
                                value
                                  ? setProdname(value.name) &&
                                    setPrdRate(value.unitprice)
                                  : setProdname("") && setPrdRate(0);
                                handleChange(index, e);
                                products.filter(handleRate);
                                setPrdRate(0);
                              }}
                            />{" "}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="quantity"
                            onClick={(e) => handleChange(index, e)}
                            onChange={(e) => {
                              handleChange(index, e);
                              products.filter(handleRate);
                            }}
                            value={itemField.quantity}
                            placeholder="0"
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="unitPrice"
                            onChange={(e) => handleChange(index, e)}
                            value={itemField.unitPrice}
                            placeholder="0"
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="discount"
                            onChange={(e) => handleChange(index, e)}
                            value={itemField.discount}
                            placeholder="0"
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="amount"
                            onChange={(e) => handleChange(index, e)}
                            value={
                              itemField.quantity * itemField.unitPrice -
                              (itemField.quantity *
                                itemField.unitPrice *
                                itemField.discount) /
                                100
                            }
                            disabled
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => handleRemoveField(index)}>
                            <DeleteOutlineRoundedIcon
                              style={{ width: "20px", height: "20px" }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  : invoiceData.items.map((itemField, index) => (
                      <TableRow key={index}>
                        <TableCell
                          onInput={handleChange}
                          scope="row"
                          style={{ width: "40%" }}
                        >
                          <Autocomplete
                            {...productProps}
                            PaperComponent={CustomPaper}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Select Product")}
                                margin="normal"
                                name="itemName"
                                value={itemField.itemName}
                              />
                            )}
                            value={products.name}
                            onChange={(e, value) => {
                              value
                                ? setProdname(value.name) &&
                                  setPrdRate(value.unitprice)
                                : setProdname("") && setPrdRate(0);
                              handleChange(index, e);
                              products.filter(handleRate);
                              setPrdRate(0);
                            }}
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="quantity"
                            onInput={(e) => {
                              handleChange(index, e);
                              products.filter(handleRate);
                            }}
                            value={itemField.quantity}
                            placeholder="0"
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="unitPrice"
                            onChange={(e) => handleChange(index, e)}
                            value={itemField.unitPrice}
                            placeholder="0"
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="discount"
                            onChange={(e) => handleChange(index, e)}
                            value={itemField.discount}
                            placeholder="0"
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="amount"
                            onChange={(e) => handleChange(index, e)}
                            value={
                              itemField.quantity * itemField.unitPrice -
                              (itemField.quantity *
                                itemField.unitPrice *
                                itemField.discount) /
                                100
                            }
                            disabled
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => handleRemoveField(index)}>
                            <DeleteOutlineRoundedIcon
                              style={{ width: "20px", height: "20px" }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.addButton}>
            <button onClick={handleAddField}>+</button>
          </div>
        </div>

        <div className={styles.invoiceSummary}>
          <div className={styles.summary}>{t("Invoice Summary")}</div>
          <div className={styles.summaryItem}>
            <p>{t("Sub total")}:</p>
            <h4>{subTotal}</h4>
          </div>
          <div className={styles.summaryItem}>
            <p>{t("VAT")}(%):</p>
            <h4>{vat}</h4>
          </div>
          <div className={styles.summaryItem}>
            <p>{t("Total")}</p>
            <h4 style={{ color: "black", fontSize: "18px", lineHeight: "8px" }}>
              {currency} {toCommas(total)}
            </h4>
          </div>
        </div>

        <div className={styles.toolBar}>
          <Container>
            <Grid container>
              <Grid item style={{ marginTop: "16px", marginRight: 10 }}>
                <TextField
                  type="text"
                  step="any"
                  name="rates"
                  id="rates"
                  value={rates}
                  onChange={handleRates}
                  placeholder="e.g 10"
                  label={t("Tax in (%)")}
                />
              </Grid>
              <Grid item style={{ marginRight: 10 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={t("Due date")}
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    minDate={new Date()}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item style={{ width: 270, marginRight: 10 }}>
                <Autocomplete
                  {...defaultProps}
                  id="debug"
                  debug
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Select currency")}
                      margin="normal"
                    />
                  )}
                  value={currency.value}
                  onChange={(event, value) => {
                    value ? setCurrency(value.value) : setCurrency("");
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={styles.note}>
          <h4>{t("Note/Payment Info")}</h4>
          <textarea
            style={{ border: "solid 1px #d6d6d6", padding: "10px" }}
            placeholder={t("Provide additional details or terms of service")}
            onChange={(e) =>
              setInvoiceData({ ...invoiceData, notes: e.target.value })
            }
            value={invoiceData.notes || '.'}
          />
        </div>

        {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            style={{ justifyContentContent: "center" }}
            type="submit"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
            disabled={usrprfl?.data?.result === 0}
          >
            {t("Save and Continue")}
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default Invoice;
